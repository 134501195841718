import tokenExpired from "./checkExpiry";
import fetchNewToken from "./newTokenFetcher";
import i18n from "../i18n";

const PUBLIC_API_ROUTES = {
  "/app/status": true
};

const authHeaderFn = (headers = {}, url = "") => {
  const lng = i18n.language;
  const lngHeader = {
    "Accept-Language": lng === "fr" ? "fr-FR" : lng,
  };

  if (url && PUBLIC_API_ROUTES[url]) return Object.assign(headers, lngHeader);

  if (!tokenExpired()) {
    const authorization = {};
    if (localStorage.getItem("token")) {
      authorization.Authorization = localStorage.getItem("token");
    }
    const modifyHeader = Object.assign(headers, authorization, lngHeader);
    return modifyHeader;
  } else {
    const authorization = {};
    if (localStorage.getItem("token")) {
      authorization.Authorization = localStorage.getItem("token");
    }
    fetchNewToken();
    // const authorization = { Authorization: localStorage.getItem("token") };
    const modifyHeader = Object.assign(headers, authorization, lngHeader);
    return modifyHeader;
  }
};

export default authHeaderFn;
