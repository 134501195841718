
import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback
} from 'react';
import {
  createStompClient,
  connectStompClient,
  disconnectStompClient,
  subscribeToTopic,
  sendMessageToTopic
} from '@iso/utils/webSocketUtils';
import { getSubDomain, getTokenData } from '@iso/utils/helpers';
import { STAGING_BASE_URL, DEV_BASE_URL } from '../config/app.config';

const WebSocketContext = createContext();

window.stompClient = null;

const getEnvUrl = () => {
  let BASE_URL = DEV_BASE_URL + "/sqsocket";

  if (process.env.REACT_APP_ENV === "development") {
    if (['dev-sq-atnm', 'dashboard-atnm'].indexOf(getSubDomain()) > -1) {
      BASE_URL = "http://18.169.18.240:8084/sqsocket";
    } 
  } else if (process.env.REACT_APP_ENV === "pre-production") {
    BASE_URL = STAGING_BASE_URL + "/sqsocket";
  } else if (process.env.REACT_APP_ENV === "production") {
    BASE_URL = "https://safetyqube.uksouth.cloudapp.azure.com/sqbroker/sqsocket";
  }

  return BASE_URL;
}

export const WebSocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [connected, setConnected] = useState(false);
  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const [adId, setAdId] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        const { adId } = getTokenData();

        setToken(storedToken);
        setAdId(adId);

        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const sockjsURL = `${getEnvUrl()}?authorization=${token}`;
  const connectHeaders = {
    authorization: token,
    'ws-id': adId
  }

  useEffect(() => {
    if (token) {
      console.log("Creating stomp client");
      const client = createStompClient(sockjsURL, connectHeaders);
      window.stompClient = client;

      const onConnect = () => {
        if (connected) setConnected(false);
        console.log('Connected to WebSocket');
        setTimeout(() => setConnected(true));
      };

      const onError = (error) => {
        setError(error);
        console.log('Connection error', error);
        setConnected(false);
      };

      connectStompClient(client, onConnect, onError);

      return () => {
        disconnectStompClient(client);
      };
    }
  }, [sockjsURL, connectHeaders?.authorization, token]);

  const subscribe = (destination, callback) => {
    const subscription = subscribeToTopic(window.stompClient, destination, (message) => {
      const parsedMessage = JSON.parse(message.body);
      setMessages((prevMessages) => [...prevMessages, parsedMessage]);
      if (callback) callback(parsedMessage);
    });
    return subscription;
  };

  const sendMessage = (destination, body) => {
    sendMessageToTopic(
      window.stompClient,
      destination,
      JSON.stringify({ messageContent: JSON.stringify(body) }),
      token
    );
  };

  return (
    <WebSocketContext.Provider value={{
      messages,
      error,
      subscribe,
      sendMessage,
      connected
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
