import jwtDecode from "jwt-decode";
import moment from "moment";
import { resolveRequestUM, userManagementApi } from "@apiUM";
import { cloneDeep, isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { timezones } from "./timezones";
import momentTimezone from "moment-timezone";
import showNotification from "./notification";

export const getTimestamp = (date) => new Date(date || Date.now()).getTime();

export const getFileredRoutes = (routes, permissions) => routes.filter(route => {
  if (route.hide) return false;

  let and = true;

  if (route.children) route.children = getFileredRoutes(route.children, permissions);

  (route.requiredPermissions || []).forEach(p => {
    if (Array.isArray(p)) {
      let or = false;
      p.forEach(p => or |= permissions[p]);

      and &= or;
    } else {
      and &= permissions[p];
    }
  });

  return and;
});

export const whitelistLanguages = ['en', 'fr'];

export const generateUniqueId = (returnUuid) => (
  returnUuid ? uuidv4() : (Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36))
);

export const setTempKeys = (data = []) => data.map(d => ({ ...d, key: d.key || generateUniqueId() }));

export const removeTempKeys = (data = []) => data.map(d => {
  delete d.key;
  return d;
});

export const getTokenData = (loginToken = "") => {
  const token = localStorage.getItem("token") || loginToken;
  return token ? jwtDecode(token) : {};
}

export const getLetterByNumber = (number) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return letters[number];
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const datePickerReadableFormat = (value, picker) => (
  value ? moment(value).format(picker === "month" ? "MMM YYYY" : "MMM DD, YYYY") : null
);
export const objectToQueryString = (obj, dontSendEmpty = false) => {
  if (!obj) return '';
  return Object.keys(obj)
    .filter((key) => !dontSendEmpty || obj[key])
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
};

export const getOwnersString = (owners = [], type, { firstName, lastName }) =>
  (type === "Checklist" ? `${firstName} ${lastName}` : owners.join(", ")) ||
  "-";

export const isToAction = (record) => {
  if (record?.type === "Checklist" || record?.type === "Inspection") return true;

  if (record?.type === "Hazard" || record?.type === "Incident") {
    if (
      (record?.isRaiser === true &&
        record?.status === "Resolution Approved by EHS Team") ||
      (record?.status === "Open" && record?.isOwner === true) ||
      (record?.isResolutionTeam === true &&
        (record?.status === "Resolution not Approved by EHS Team" ||
          record?.status === "Resolution not Approved by Raiser" ||
          record?.status === "Assigned")) ||
      (record?.status === "Resolution Implemented" &&
        record?.isEHSMember === true &&
        record?.isRaiser === null &&
        record?.isOwner === null &&
        record?.isResolutionTeam === null &&
        record?.isFYIMember === null)
    ) {
      return true;
    }
  }

  if (record?.type === "Task") {
    return record?.isOwner;
  }

  return false;
};


export const saveFiltersToSessionStorage = (filterKey = '', filters = {}) => {
  const filtersStr = JSON.stringify(filters);
  sessionStorage.setItem(filterKey, filtersStr);
}

export const getFiltersFromSessionStorage = (filterKey = '', defaultFilterState = {}) => {
  const filters = sessionStorage.getItem(filterKey);

  if (!filters) return defaultFilterState;

  const parsedFilters = JSON.parse(filters);
  return parsedFilters;
}

export const createSessionStorageFilterKey = (baseFilterKeyName = '') => {
  return `${baseFilterKeyName}`;
}

export const clearSessionStorageFilter = (filterKey = '') => sessionStorage.removeItem(filterKey);

export const setLanguageTimezone = async (token, lang) => {

  if (token) {
    const params = {}

    const tokenLanguage = jwtDecode(token)["selectedLanguage"];
    const tokenTimezone = jwtDecode(token)["selectedTimezone"];

    if (!tokenLanguage || tokenLanguage === "") {
      params["selectedLanguage"] = Intl.DateTimeFormat().resolvedOptions().locale.split("-")[0];
    } else if (whitelistLanguages.indexOf(tokenLanguage) >= 0) {
      lang.changeLanguage(tokenLanguage);
    } else {
      lang.changeLanguage("en");
    }

    if (!tokenTimezone || tokenTimezone === "") {
      params["selectedTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (Object.keys(params).length > 0) {
      params["id"] = jwtDecode(token)["userId"];
      params["roleId"] = jwtDecode(token)["roleId"];

      const promise = userManagementApi.updateEmployee(params);
      const response = await resolveRequestUM(promise);
    }
  }
}

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const removeKeysFromNestedArray = (list = [], lookInToKeys = [], keysToRemove = []) => {
  const mapAndRemoveId = (list) => {
    return list.map(item => {
      if (typeof item === "object") {
        keysToRemove.forEach(key => delete item[key]);
      }

      lookInToKeys.forEach(lookInToKey => {
        const { name, type } = lookInToKey;

        if (type === "array" && Array.isArray(item[name])) {
          item[name] = mapAndRemoveId(item[name]);
        } else if (type === "object" && typeof item[name] === "object") {
          keysToRemove.forEach(key => delete item[name][key]);
          Object.keys(item[name]).forEach(key => {
            if (Array.isArray(item[name][key])) {
              item[name][key] = mapAndRemoveId(item[name][key]);
            }
          });
        }
      });

      return item;
    });
  }

  return mapAndRemoveId(cloneDeep(list));
}

export const getSearchParamsUrlStr = (baseUrl = '', data = {}) => {
  let url = baseUrl;

  Object.keys(data).forEach((key, index, arr) => {
    const value = data[key];
    if (index === 0) url += '?';
    url += `${key}=${value}`;
    if (arr.length - 1 !== index) url += '&';
  })

  return url.toString();
}

export const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getTimezoneName = (timezone = "") => {
  let name = typeof timezone === "string" && timezone.includes("/") ? timezone : Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if(!name && (getTokenData() || {}).selectedTimezone) name = getTokenData().selectedTimezone;

  if (typeof name === "string") return name.split("/").join(" / ");

  return name || "";
}

export const getTimezoneOffset = (timezone = "") => {
  if (!timezone) {
    const offset = new Date().getTimezoneOffset() / 60;
    return "GMT " + (offset > 0 ? "-" : "+") + Math.abs(offset);
  }

  const now = new Date();

  const offsetInMinutes = now.getTimezoneOffset() + new Date(now.toLocaleString("en-US", { timeZone: timezone })).getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;
  const offsetSign = offsetInHours < 0 ? "-" : "+";
  const offsetInHoursAbs = Math.abs(offsetInHours);

  const offsetFormatted = `GMT ${offsetSign}${offsetInHoursAbs}`;

  return offsetFormatted;
}

export const list = (timezone) => timezones.filter(a => a.label.includes(timezone));


export const timezoneSuffix = (timezone) => {
  return `${
    list(timezone)[0]?.tzCode?.replace("/", " / ")
  }`;
};

export const getTimezoneNameAndOffset = () => {
  // return `${getTimezoneName(selectedTimezone)}; ${getTimezoneOffset(selectedTimezone)}`;
  return timezoneSuffix(momentTimezone.tz.guess()).replace(/(\|\/)/g, "");
};

export const getSubDomain = () => {
  const fullUrl = window.location.host
  //window.location.host is subdomain.domain.com
  const parts = fullUrl.split('.');
  const subDomain = parts[0];
  return subDomain;
}

export const createNestedIdArrayMap = (data, keys = []) => {
  if (!Array.isArray(data)) return {};
  const finalData = {};
  let level = 0;

  const createArrayMap = (arr, m, level) => {
    arr.forEach(a => {
      m[a.id] = a;
      const findKey = (keys.length > 0 ? keys : Object.keys(a)).find(k => Array.isArray(a[k]));

      if (findKey && Array.isArray(a[findKey])) {
        const newArr = cloneDeep(a[findKey]);
        m[a.id].levelData = {};
        m[a.id].level = level + 1;
        m[a.id].levelKey = findKey;
        createArrayMap(newArr, m[a.id].levelData, level + 1);
      }
    });
  }

  createArrayMap(data, finalData, level);

  return finalData;
}

export const getParsedReactSelectOptions = (data = [], valueKey = 'value', labelKey = 'label', idKey = 'id', addIsActiveKey) => {
  let labelKeys = null;
  if (labelKey.includes('-')) labelKeys = labelKey.split('-');

  return data.map(d => ({
    value: d[valueKey],
    label: labelKeys ? `${d[labelKeys[0]]} (${d[labelKeys[1]]})` : d[labelKey],
    id: d[idKey],
    ...(addIsActiveKey ? { isActive: d.isActive } : {}),
    disabled: d.isActive === false
  })).sort((a) => (a.isActive) ? -1 : 1)
}

export const findMissingValueInArrays = (arrayA = [], arrayB = []) => {
  for (const element of arrayB) {
    if (arrayA.indexOf(element) === -1) {
      return element;
    }
  }
  return null;
}

export const extractSectionsQuestions = (data, isIncidentPage) => {
  const questions = [];

  const recurseRules = (rules = []) => {
    if (rules.length) {
      for (const rule of rules) {
        if (rule.action === 'show-question' && rule.followupQuestion) {
          questions.push(rule.followupQuestion);
          recurseRules(rule.followupQuestion.rules);
        }
      }
    }
  }

  const recurse = (node) => {
    if (node.questions && node.questions.length > 0) {
      for (const question of node.questions) {
        questions.push(question);
        recurseRules(question.rules);
      }
    }

    if (node.subSections && node.subSections.length > 0) {
      for (const subSection of node.subSections) {
        recurse(subSection);
      }
    }
  }

  for (const node of data) {
    if (isIncidentPage) {
      for (const section of node.sections) {
        recurse(section);
      }
    } else {
      recurse(node);
    }
  }

  return questions;
}

export const removeIfInvalidToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const tokenExpiry = jwtDecode(token)["exp"];
      if (tokenExpiry < Date.now() / 1000) {
        localStorage.clear();

      }
    } catch (e) {
      localStorage.clear();
    }
  }
}

export const setToken = (token) => {
  if (!token) {
    throw new Error("Token is required to set");
  }
  localStorage.setItem("token", token);
  localStorage.setItem("loggedIN", true);
  localStorage.setItem("tokenExpiry", jwtDecode(token)["exp"]);
};

export const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("loggedIN");
  localStorage.removeItem("tokenExpiry");
};

export const removeHTMLTags = (str) => {
  return str.replace(/<style[\s\S]*?<\/style>|<[^>]*>/gi, '');
}

export const compareSpecificFieldsInObjects = (obj1, obj2, fields) => {
  return fields.every(field => isEqual(obj1[field], obj2[field]));
};

export const getFileNameFromUrl = (url = "") => {
  return decodeURI(url?.split('/')?.pop() || '');
}

export const safeJsonParse = (str, defaultValueToReturn = {}) => {
  if (!str) return defaultValueToReturn;
  try {
    return JSON.parse(str);
  } catch (e) {
    return defaultValueToReturn;
  }
}

export const setOrderKey = (list) => list.map((item, index) => ({ ...item, order: index + 1 }));

export const removeInactiveValue = (data = [], keyToCheck = 'isActive') => data.filter(item => item[keyToCheck] !== false);

export const getAppEnv = () => ({
  isDev: process.env.REACT_APP_ENV === 'development',
  isProd: process.env.REACT_APP_ENV === 'production',
  isLocal: process.env.REACT_APP_ENV === undefined || process.env.REACT_APP_ENV === "local"
});

export const isTablet = () => {
  const screenWidth = window.innerWidth;
  return screenWidth >= 768 && screenWidth <= 1300;
}
export const exportTemplateData = (templateData, fileName) => {
  const json = JSON.stringify(templateData, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  showNotification('success', "File downloaded successfully");
};


export const disableArrowKeysOnInput = (event) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
  }
};

export const preventNumberInputScroll = (event) => {
  event.target.blur();
};

export const trimNewLines = (input = '') => {
  return input.replace(/^\n+|\n+$/g, '').trim();
}

export const getOrgHierarchyValuesObj = (data = {}) => {
  const orgHierarchyValues = {};

  const getValue = (key) => {
    const name = (data?.[`${key}Name`] !== "-" && data?.[`${key}Name`]) || data?.[`${key}Details`]?.[`${key}Name`];

    return {
      value: data?.[key],
      label: name,
      id: data?.[key],
    };
  }

  if (data.facility) orgHierarchyValues.facility = getValue('facility');
  if (data.department) orgHierarchyValues.department = getValue('department');
  if (data.location) orgHierarchyValues.location = getValue('location');
  if (data.subarea) orgHierarchyValues.subarea = getValue('subarea');

  return orgHierarchyValues;
}